<template>
    <front-layout>
        <div class="page__top-line d-flex flex-column justify-center align-center fill-height pa-5 pb-16"
             id="signupForm">
            <v-card flat min-width="300" max-width="600">
                <v-card-title>Sign Up</v-card-title>
                <v-container>
                    <v-form>
                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <v-stepper-step step="1">
                                    Role
                                </v-stepper-step>
                                <v-divider/>
                                <v-stepper-step v-if="!isFan" step="2">
                                    Membership Level
                                </v-stepper-step>
                                <v-divider v-if="!isFan"/>
                                <v-stepper-step :step="infoStep">
                                    User info
                                </v-stepper-step>
                                <v-divider v-if="showCheckout"/>
                                <v-stepper-step v-if="showCheckout" step="4">
                                    Checkout
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col>
                                        <v-select
                                            :items="roles"
                                            :error-messages="errors.role"
                                            label="Role"
                                            v-model="role"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-end">
                                        <v-btn :disabled="!role" class="info" @click="step = isFan ? infoStep : 2">
                                            Continue
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content v-if="!isFan" step="2">
                                <v-row>
                                    <v-col>
                                        <v-select
                                            :items="pricePlansFiltered"
                                            :error-messages="errors.price_plan"
                                            label="Membership Level"
                                            item-text="name"
                                            item-value="id"
                                            v-model="price_plan"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="couponCode"
                                            label="Coupon"
                                            :error-messages="couponError"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-switch
                                            v-model="yearly"
                                            label="Pay for a year and get two months free"
                                            class="px-3"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-space-between">
                                        <v-btn class="error" @click="step = 1">Back</v-btn>
                                        <v-btn class="info" @click="gotoUserInfo" :disabled="!price_plan">Continue
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-progress-linear v-if="loading" indeterminate/>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content :step="infoStep">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.email"
                                            label="Email"
                                            v-model="email"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.first_name"
                                            label="First name"
                                            v-model="first_name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.last_name"
                                            label="Last name"
                                            v-model="last_name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="role === 'venue'">
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.resource_name"
                                            label="Venue name"
                                            v-model="resource_name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <city-selector
                                            v-model="city"
                                            :error-messages="errors.city"
                                            :return-object="false"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="role === 'artist'">
                                    <v-col>
                                        <type-select
                                            :error-messages="errors.artist_type"
                                            v-model="artist_type"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="role === 'artist'">
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.resource_name"
                                            label="Stage name"
                                            v-model="resource_name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :error-messages="errors.password"
                                            type="password"
                                            label="Password"
                                            v-model="password"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            type="password"
                                            :error-messages="errors.password_confirmation"
                                            label="Password"
                                            v-model="password_confirmation"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-space-between">
                                        <v-btn class="error" @click="step = isFan ? 1 : 2">Back</v-btn>
                                        <v-btn class="success" @click="handleSignUp">Sign Up</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-progress-linear v-if="loading" indeterminate/>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content :step="4">
                                <stripe-checkout-card
                                    :loading="paymentLoading"
                                    @submit-start="paymentLoading = true"
                                    v-if="step === 4"
                                    @submit-card="subscribe"
                                    @submit-error="paymentLoading = false"
                                />
                            </v-stepper-content>
                        </v-stepper>
                    </v-form>
                </v-container>
                <v-container class="text-center pt-8">
                    <v-btn
                        :to="{name: 'login'}"
                        text
                        small
                    >
                        Already have an account? Login
                    </v-btn>
                </v-container>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";
import PricePlan from "@/models/PricePlan";
import Coupon from "@/models/Coupon";
import StripeCheckoutCard from "@/components/StripeCheckoutCard";
import BillingApi from "@/api/BillingApi";
import {mapMutations} from "vuex";
import CitySelector from "@/components/CitySelector";
import TypeSelect from "@/components/artist/TypeSelect";

export default {
    name: "Login",
    components: {TypeSelect, CitySelector, StripeCheckoutCard, FrontLayout},
    data: function () {
        return {
            email: '',
            first_name: '',
            last_name: '',
            resource_name: '',
            password: '',
            password_confirmation: '',
            role: null,
            roles: ['fan', 'venue', 'artist'],
            price_plan: null,
            price_plans: [],
            errors: {},
            user: [],
            city: null,
            step: 1,
            loading: false,
            artist_type: null,
            paymentLoading: false,
            yearly: true,
            couponCode: null,
            couponError: null,
        }
    },
    computed: {
        isFan() {
            return this.role === 'fan'
        },
        showCheckout() {
            if (this.isFan) {
                return false
            }

            const selectedPlan = this.$_.find(this.price_plans, {id: this.price_plan})

            if (!selectedPlan) {
                return false
            }

            return selectedPlan['monthly_price'] > 0
        },
        infoStep() {
            return this.isFan ? 2 : 3
        },
        pricePlansFiltered() {
            const filter = p => {
                const lowerName = p.name.toLowerCase();

                if (!lowerName.startsWith(this.role)) {
                    return false;
                }

                if (lowerName.includes('free')) {
                    return true
                }

                let yearlyCheck = lowerName.includes('yearly')

                if (!this.yearly) {
                    yearlyCheck = !yearlyCheck
                }

                return yearlyCheck;
            };

            return this.$_.sortBy(this.price_plans.filter(filter), p => p.name.toLowerCase().includes('free') ? 0 : 1)
        },
        selectedPlan() {
            return this.$_.find(this.price_plans, {id: this.price_plan})
        },
        signUpData() {
            const data = {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                role: this.role,
                price_plan: this.price_plan,
                first_name: this.first_name,
                last_name: this.last_name,
                city: this.city,
                resource_name: this.resource_name,
            }

            if (this.role === 'artist') {
                data.artist_type = this.artist_type
            }

            return data;
        }
    },
    methods: {
        async handleSignUp() {
            const data = this.signUpData;
            this.errors = []

            const catchErr = e => {
                this.loading = false;
                this.errors = e.response.data.errors;

                if (this.errors.role) {
                    this.step = 1;
                    return;
                }

                if (this.errors.price_plan) {
                    this.step = 2;
                }
            };

            this.loading = true

            this.$auth
                .register({
                    data,
                    staySignedIn: true,
                    redirect: null
                })
                .then(() => {
                    this.loading = false;

                    if (this.role === 'fan') {
                        return this.$router.push({name: 'dashboard.index'})
                    }

                    if (!this.showCheckout) {
                        return this.$router.push(this.$loginRedirectRoute(this.role))
                    }

                    this.step = 4;
                })
                .catch(catchErr)
        },
        subscribe(method) {
            BillingApi.updateSubscription(method, this.selectedPlan["stripe_id"]).then(() => {
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Subscription confirmed'})
                this.$router.push(this.$loginRedirectRoute(this.role))
            })
        },
        ...mapMutations(['showSnackBar']),

        findPricePlanId(needle) {
            const plan = this.$_.find(this.pricePlansFiltered, plan => plan.name.toLowerCase().includes(needle))
            if (plan) return plan.id
        },
        setPredefined() {
            this.role = this.$route.query.role
            this.price_plan = this.findPricePlanId(this.$route.query.price_plan)
            if (this.price_plan) this.step = 2
        },
        async gotoUserInfo() {
            if (!this.couponCode) {
                this.step = 3
                return
            }

            this.loading = true
            const status = await (new Coupon({id: this.couponCode}).check());
            this.loading = false

            if (!status) {
                this.couponError = 'The code is invalid'
                return
            }

            this.step = 3
        }
    },
    async mounted() {
        this.price_plans = await PricePlan.custom('/price-plans/public').get()
        this.predefinition = false
        if (this.$route.query.role) {
            this.predefinition = true
            this.setPredefined()
        }
    },
    watch: {
        role() {
            if(!this.predefinition) this.price_plan = null
            this.predefinition = false
        },
        yearly() {
            this.price_plan = null
        }
    }
}
</script>
